/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable no-undef */

const colors = require('tailwindcss/colors')
const theme = require('tailwindcss/defaultTheme')
const designSystem = require('./tailwind.config.design-system')
const plugin = require('tailwindcss/plugin')

module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './components/*.{js,ts,jsx,tsx}',
    './partials/**/*.{js,ts,jsx,tsx}',
    './scalis-components/**/*.{js,ts,jsx,tsx}',
    './src/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    extend: {
      size: {
        22: '5.5rem',
      },
      fontSize: {
        ...theme.fontSize,
        xxxs: '0.5rem',
        xxs: '0.625rem',
        'body-2': '14px',
        'body-1': '10px',
      },
      screens: {
        ...theme.screens,
        xs: '475px',
        xxs: '375px',
      },
      animation: {
        'bottom-slide': 'slide-bottom 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
        'left-slide': 'slide-left 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
        'right-slide': 'slide-right 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
        'top-slide': 'slide-top 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
        'collapse-height-open': 'collapse-height-open 0.5s ease-in-out both',
        'collapse-height-close': 'collapse-height-close 0.5s ease-in-out both',
        'scroll-shadow': 'scroll-shadow 0.2s ease-in-out both',
        'action-bar-up': 'action-bar-up 1s ease-in-out both',
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        'slide-back-right': 'slide-back-right 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
      },
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
        'collapse-height-open': {
          '0%': {
            height: '0',
          },
          '100%': {
            height: 'full',
          },
        },
        'collapse-height-close': {
          '0%': {
            height: 'full',
          },
          '100%': {
            height: '0',
          },
        },
        'slide-bottom': {
          '0%': {
            '-webkit-transform': 'translateY(300px)',
            transform: 'translateY(300px)',
          },
          '100%': {
            '-webkit-transform': 'translateX(0)',
            transform: 'translateX(0)',
          },
        },
        'slide-back-bottom': {
          '100%': {
            '-webkit-transform': 'translateY(300px)',
            transform: 'translateY(300px)',
          },
          '0%': {
            '-webkit-transform': 'translateX(0)',
            transform: 'translateX(0)',
          },
        },
        'slide-left': {
          '0%': {
            '-webkit-transform': 'translateX(-300px)',
            transform: 'translateX(-300px)',
          },
          '100%': {
            '-webkit-transform': 'translateX(0)',
            transform: 'translateX(0)',
          },
        },
        'slide-right': {
          '0%': {
            transform: 'translateX(100%)',
          },
          '100%': {
            transform: 'translateX(0)',
          },
        },
        'slide-back-right': {
          '0%': {
            transform: 'translateX(0)',
          },
          '100%': {
            transform: 'translateX(100%)',
          },
        },
        'slide-top': {
          '0%': {
            '-webkit-transform': 'translateY(-300px)',
            transform: 'translateY(-300px)',
          },
          '100%': {
            '-webkit-transform': 'translateX(0)',
            transform: 'translateX(0)',
          },
        },
        'scroll-shadow': {
          '0%': {
            boxShadow: 'none',
          },
          '100%': {
            boxShadow: '0px 7px 4px -3px rgba(0, 0, 0, 0.25)',
          },
        },
        'action-bar-up': {
          '0%': { bottom: '-60px' },
          '100%': { bottom: '2.5rem' },
        },
      },
      colors: {
        'chardonnay-200': '#FFDFA9',
        'forest-green': '#B8F5A1',
        'admin-blue-light': '#D0E7FF',
        'admin-blue-dark': '#264999',
        'admin-white': '#FFFFFF',
        'admin-gray': '#737373',
        'scalis-logo-color': '#0A21D3',
        monza: {
          200: '#FFC9C9',
          300: '#FDA4A4',
        },
        subtle: {
          hover: '#F5F7FA',
          pressed: '#DDE5ED',
        },
        neutral: {
          primary: '#151A1D',
          secondary: '#57697F',
          rest: '#D1DAE4',
        },
        primary: {
          DEFAULT: '#0020D3',
          background: '#E7F3FF',
          text: '#0B26A4',
          'background-hover': '#D3E9FF',
          'background-pressed': '#B0D5FF',
          40: '#F2F4FD',
          50: '#C9D5FB',
          60: '#95AAF9',
          100: '#E7F2F3',
          200: '#C5E0E2',
        },
        secondary: {
          DEFAULT: '#F2F3F2',
          background: '#0020D3',
          'background-hover': '#0B26A4',
        },
        error: {
          DEFAULT: '#FD3E36',
          destructive: '#BA1A1A',
          40: '#FFF5F5',
          50: '#FFD9D8',
          80: '#FD3E36',
          90: '#B41B30',
          95: '#82102A',
          100: '#66001E',
          200: '#F3AB9B',
          600: '#C63F3F',
        },
        success: {
          90: '#2B8D12',
          80: '#5AC424',
          dark: '#267714',
        },
        white: {
          DEFAULT: '#FFF',
          70: '#595959',
          80: '#737373',
          85: '#999',
          90: '#CCCCCC',
          95: '#F2F2F2',
        },
        'off-white': {
          60: '#E5F2FF',
          80: '#CFE4FF',
        },
        bluegray: {
          40: '#F1F2F5',
          50: '#D7DAE2',
          60: '#BFC4D1',
          80: '#6D7995',
          100: '#31426A',
        },
        'dark-color-text': {
          60: '#264999',
          80: '#061F6F',
          90: '#021455',
          100: '#000D3D',
        },
        accent: {
          1100: '#001855',
        },
        'accent-3': {
          80: '#003D94',
        },
        'accent-2': {
          60: '#E5F2FF',
          70: '#D8EBFF',
        },
        'accent-1': {
          40: '#F2F5FA',
          80: '#003D94',
        },
        'white-1': {
          70: '#595959',
          95: '#F2F2F2',
        },
        'primary-1': {
          40: '#F2F4FD',
          50: '#C9D5FB',
          60: '#95AAF9',
          80: '#0020D3',
          90: '#001298',
        },
        gray: {
          ...colors.gray,
          50: '#D7DAE2',
        },
        borders: {
          DEFAULT: '#E4E4E7',
          1: '#D8DBDF',
          2: '#B9BBC6',
          borders: '#E2E8F0',
          ring: '#285BFF',
          input: '#94A3B8',
        },
        'borders-2': {
          'not-interactive': '#DDDDE3',
          interactive: '#D3D4DB',
          hover: '#B9BBC6',
        },
        background: {
          purest: '#F0F4FF',
          muted: '#E2E8F0',
          'muted-background': '#EDEEF1',
          2: '#F8FAFF',
          hover: '#F8FAFC',
          pressed: '#F1F5F9',
          blue: '#F1F7FD',
          primarybackground: '#E7F3FF',
        },
        warning: {
          70: '#FFCE65',
        },
        'pale-sky': {
          50: '#F7F8F8',
        },
        typography: {
          'low-contrast': '#6B7280',
          'low-contrast-text': '#64748B',
          color: '#0B26A4',
          'high-contrast': '#0F172A',
          muted: '#94A3B8',
        },
        icons: {
          DEFAULT: '#0020D3',
          secondary: '#0F172A',
          'low-contrast': '#64748B',
        },
        actions: {
          secondary: '#0F172A',
          muted: '#94A3B8',
          DEFAULT: '#0020D3',
          hover: '#0B26A4',
          pressed: '#07155F',
        },
        destructive: {
          default: '#BA1A1A',
          hover: '#9B1919',
          pressed: '#801C1C',
          background: '#FEF2F2',
          'background-2': '#FEE2E2',
        },
        extra: {
          '01': '#E5C9FB',
          '01-2': '#B978ED',
          '02': '#C9D5FB',
          '02-2': '#7894ED',
          '03': '#BDE9FD',
          '03-2': '#72CBF3',
          '04': '#C8FBF6',
          '04-2': '#78EDE1',
          '05': '#A6FBD0',
          '05-2': '#75F0B2',
          '06': '#DFFFB1',
          '06-2': '#BFF76E',
          '07': '#D8B8A1',
          '07-2': '#BC8B67',
          '08': '#FDB894',
          '08-2': '#F0AB87',
          '09': '#FDE7E7',
          '09-2': '#ED8E8E',
          10: '#FFF2D1',
          '10-2': '#FFE49F',
        },
        'score-colors': {
          '01': '#78CE5C',
          '02': '#B4EB6B',
          '03': '#D8EB6B',
          '04': '#F9EF52',
          '05': '#FFDD70',
          '06': '#FFC776',
          '07': '#FC9E73',
          '08': '#FF7A76',
        },
        ...designSystem.theme.extend.colors,
      },
      backgroundColor: designSystem.theme.extend.backgroundColor,
      borderColor: designSystem.theme.extend.borderColor,
      borderRadius: {
        '4xl': '32px',
      },
      width: {
        'popover-trigger': 'var(--radix-popover-trigger-width)',
        inherit: 'inherit',
      },
      brightness: {
        60: '.6',
        70: '.7',
      },
      boxShadow: {
        dropdown: '0px 10px 15px -3px #12121714',
        'candidate-box-dragging':
          '0px -2px 8px -2px rgba(0, 13, 61, 0.10), 0px 4px 6px -4px rgba(0, 13, 61, 0.10), 0px 10px 15px -3px rgba(0, 13, 61, 0.10)',
        'drop-list': '0px 10px 15px -3px rgba(18, 18, 23, 0.08), 0px 4px 6px -2px rgba(18, 18, 23, 0.05)',
        ...designSystem.theme.extend.boxShadow,
      },
      lineHeight: designSystem.lineHeight,
      borderWidth: {
        DEFAULT: '1px',
      },
      writingMode: {
        'vertical-rl': 'vertical-rl',
      },
    },
  },
  plugins: [
    plugin(({ addUtilities }) => {
      addUtilities({
        '.writing-mode-vertical-rl': {
          'writing-mode': 'vertical-rl',
        },
      })
    }),
  ],
}
